<template>
  <div class="st-slider-two-rows" data-t="st-slider-two-rows">
    <div class="navigation">
      <div class="title-navigation">
        <slot name="titleNavigation">
          <NuxtI18nLink
            v-if="title && showAllLink"
            :to="showAllLink"
            class="title-wrapper"
          >
            <StIcon v-if="iconLink" :icon-link="iconLink" class="icon" />
            <StIcon v-else-if="icon" :icon="icon" class="icon" />
            <h2 class="title" data-t="link-wqf5-casino">{{ title }}</h2>
            <StIcon name="chevron-right" size="18" class="chevron" />
          </NuxtI18nLink>
        </slot>
      </div>
      <div class="right-buttons">
        <StButton
          v-if="gamesTotalCount || showAllLink"
          class="all-button"
          size="m"
          :to="showAllLink"
          type="text-only"
          :data-t="`button-category-${showAllLink.split('/').pop()}-boa5`"
        >
          {{ t('casino.watchAll') }}
          <span v-if="gamesTotalCount" data-t="events-count-hhge">
            {{ gamesTotalCount }}
          </span>
        </StButton>
        <div v-if="isVisibleArrows" class="buttons">
          <StButton
            class="st-slider-arrow left"
            icon="chevron-left"
            size="s"
            type="gray"
            :disabled="arrivedState.left"
            @click="scrollLeft"
          />
          <StButton
            class="st-slider-arrow right"
            icon="chevron-right"
            type="gray"
            size="s"
            :disabled="arrivedState.right"
            @click="scrollRight"
          />
        </div>
      </div>
    </div>
    <div
      ref="gamesSlider"
      class="games-slider"
      :class="{ 'no-scroll': !props.scrollable }"
    >
      <component :is="props.listTag" class="games">
        <slot />
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '../StIcon/types'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    showAllLink: string
    rows?: number | string
    listTag?: 'div' | 'ul'
    disableArrows?: boolean
    scrollable?: boolean
    scrollStep?: number
    title: string
    gamesTotalCount: number | string
    icon?: IconName
    iconLink?: string
    arrivedStateOffset?: {
      left?: number
      right?: number
      top?: number
      bottom?: number
    }
  }>(),
  {
    scrollable: true,
    rows: 1,
    listTag: 'div',
    scrollStep: 100,
  },
)

const rows = computed(() => `repeat(${props.rows}, auto)`)
const gamesSlider = useTemplateRef<HTMLDivElement>('gamesSlider')

const { x, arrivedState, isScrolling } = useScroll(gamesSlider, {
  behavior: 'smooth',
  offset: props.arrivedStateOffset,
})

function scrollLeft() {
  x.value -= props.scrollStep
}
function scrollRight() {
  x.value += props.scrollStep
}

const isVisibleArrows = computed(() => {
  if (props.disableArrows) return false

  return props.scrollable || isScrolling.value
})

defineExpose({
  scrollToStart: () => {
    gamesSlider.value?.scrollTo({ left: 0, behavior: 'smooth' })
  },
})
</script>

<style scoped>
.games-slider {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;

  overflow: auto hidden;
  display: flex;

  margin-top: var(--spacing-150);
  margin-left: calc(var(--spacing-050) * -1);
  padding-left: var(--spacing-050);

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.games {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: v-bind(rows);
  gap: var(--spacing-150) var(--spacing-125);

  list-style: none;
}

.navigation {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  gap: var(--spacing-100);
  margin-left: var(--spacing-200);
}

.right-buttons {
  display: flex;
  height: min-content;
  margin-top: auto;

  .all-button {
    gap: var(--spacing-050);
  }
}

.title-wrapper {
  display: inline-flex;
  gap: var(--spacing-050);
  align-items: center;

  color: var(--text-primary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }
}

.title {
  margin: 0;
  font: var(--desktop-text-lg-semibold);

  &:hover {
    + .chevron {
      transform: translateX(4px);
    }
  }
}
</style>
