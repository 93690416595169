<template>
  <div data-t="category-slider-17ia" :class="classes">
    <StSliderDynamicRows
      v-if="games?.length || isLoading"
      ref="slider"
      :rows="currentRows"
      :list-tag="listTag"
      class="slider"
      :scroll-step="220"
      :title="title"
      :items-gap="12"
      :arrow-offset-vertical="18"
      :arrived-state-offset="arrivedStateOffset"
      :show-all-link="link"
      :games-total-count="gamesTotalCount"
      :icon-link="iconLink"
    >
      <template #titleNavigation>
        <slot name="titleNavigation">
          <StSectionHeader
            v-if="withHeader && title"
            :link="link"
            :title="title"
            :title-icon="iconName"
            :title-icon-svg="icon"
            :title-icon-link="iconLink"
            :data-t="{ titleWrapper: `category-title-${title}-opyt` }"
          >
            <template #desktop>
              <div class="header">
                <NuxtI18nLink
                  :to="link"
                  class="title-wrapper"
                  :data-t="`category-title-${title}-opyt`"
                >
                  <StIcon v-if="iconLink" :icon-link="iconLink" class="icon" />
                  <StIcon v-else-if="iconName" :name="iconName" class="icon" />
                  <h3 class="title">{{ title }}</h3>
                  <StIcon name="chevron-right" size="18" />
                </NuxtI18nLink>
              </div>
            </template>
          </StSectionHeader>
        </slot>
      </template>

      <template v-if="isLoading">
        <div v-for="i in skeletonCount" :key="i">
          <GameCardSkeleton class="skeleton" />
        </div>
      </template>
      <template v-else>
        <div v-for="game of games" :key="game.gameId">
          <GameCard
            :game="game"
            class="card-game"
            :show-player-count="showPlayerCount"
          />
        </div>
        <div v-for="item in emptyCardLength" :key="item">
          <div class="card-empty" data-t="empty-card-mg68" />
        </div>
      </template>
    </StSliderDynamicRows>
  </div>
</template>

<script setup lang="ts">
import StSlider from '@st/ui/components/StSlider/StSlider.vue'
import type { Game } from '../../types'
import GameCard from '../GameCard/GameCard.vue'

const props = withDefaults(
  defineProps<{
    size?: 's' | 'm' | 'l'
    title?: string
    icon?: string | null
    iconName?: string
    link: string
    games?: Game[]
    gamesTotalCount: number | string
    fillPlugsTo?: number
    withHeader?: boolean
    withShowAllButtonInSlider?: boolean
    isLoading?: boolean
    row?: number | string
    listTag?: 'div' | 'ul'
    showPlayerCount?: boolean
    iconLink?: string
  }>(),
  {
    title: '',
    size: 's',
    showPlayerCount: true,
    fillPlugsTo: 6,
    withHeader: true,
    isLoading: false,
    iconName: 'casino',
    withShowAllButtonInSlider: false,
  },
)

const classes = computed(() => `size-${props.size}`)
const emptyCardLength = computed(() => {
  if (!props.games || props.games?.length > props.fillPlugsTo) return 0
  return props.fillPlugsTo - props.games.length
})

const arrivedStateOffset = computed(() =>
  props.withShowAllButtonInSlider ? { right: 100 } : undefined,
)

const currentRows = computed(() => {
  if (props.row) {
    return Number(props.row) === 2 ? 2 : 1
  }
  return (props.games?.length ?? 0) > 12 ? 2 : 1
})

const skeletonCount = computed(() => (currentRows.value === 2 ? 18 : 9))

const sliderRef = useTemplateRef<InstanceType<typeof StSlider>>('slider')

defineExpose({
  scrollToStart: () => {
    sliderRef.value?.scrollToStart()
  },
})
</script>

<style scoped>
.title-wrapper {
  cursor: pointer;

  display: inline-flex;
  gap: var(--spacing-050);
  align-items: center;

  color: var(--text-primary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  margin: 0;
  font: var(--desktop-text-lg-semibold);
}

.button {
  cursor: pointer;
  height: fit-content;
}

.icon {
  margin-right: var(--spacing-050);
  color: var(--icon-tertiary);
}

.card-game {
  width: 216px;
}

.size-m {
  .title {
    font: var(--desktop-text-2xl-semibold);
  }

  .icon {
    width: 32px;
    height: 32px;
    opacity: 0.48;
  }
}

.card-empty {
  width: 220px;
  height: 146px;
  margin-top: var(--spacing-050);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);
}

.skeleton {
  width: 220px;
  height: 186px;
}

.show-all {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);
  align-items: center;
  justify-content: center;

  min-width: 92px;
  margin-top: 50px; /* stylelint-disable-line */

  font: var(--mobile-text-medium);
}

.current-count {
  font: var(--mobile-text-regular);
  color: var(--text-tertiary);
}
</style>
